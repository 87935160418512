
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  useStore,
  useContext,
  watch,
} from '@nuxtjs/composition-api';

import useForm from '~/composables/useForm';
import useCountries from '~/composables/useCountries';
import useModalDialog from '~/composables/useModalDialog';

interface ConsultationForm {
  site: string;
  page_url?: string;
  lang: string;
  first_name: string;
  last_name: string;
  company_name: string;
  email: string;
  mobile_phone: string;
  country: string;
  business_needs_pain_points_comments: string;
  marketing_yes: boolean;
  gdpr_yes: boolean;
}

const FORM_DEFAULTS = {
  first_name: '',
  last_name: '',
  mobile_phone: '',
  email: '',
  company_name: '',
  country: '',
  business_needs_pain_points_comments: '',
  marketing_yes: false,
  gdpr_yes: false,
};

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const config = store.getters['config/getConfig'];
    const lang: string = store.getters['config/getActiveLanguage'];
    const form = ref();
    const { formSuccess, formPending, submit, recaptcha, isSubmitDisabled } =
      useForm({ confirm: false });
    const { $gtm, nuxtState } = useContext();
    const {
      isOpen: infoModalIsOpen,
      open: openInfoModal,
      close: closeInfoModal,
    } = useModalDialog('info');

    const formData = reactive<ConsultationForm>({
      site: config.domain,
      lang,
      ...FORM_DEFAULTS,
    });

    const clearForm = () => {
      Object.assign(formData, {
        site: config.domain,
        lang,
        ...FORM_DEFAULTS,
      });

      nextTick(() => form.value.reset());
    };
    const isFormStartEventSent = ref(false);
    const { getCountriesList } = useCountries();
    const countries = getCountriesList();

    const submitForm = async () => {
      try {
        const uuid = nuxtState.config.consultFormUuid;
        await submit({ ...formData, page_url: window.location.href }, uuid);
        $gtm.push({
          event: 'leadform_sent',
        });
        openInfoModal();
        clearForm();
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };

    const pushFormStartEvent = () => {
      if (!isFormStartEventSent.value) {
        $gtm.push({
          event: 'form_start',
        });
      }
      isFormStartEventSent.value = true;
    };

    watch(formData, () => {
      pushFormStartEvent();
    });

    return {
      form,
      formData,
      countries,
      formSuccess,
      formPending,
      submitForm,
      recaptcha,
      infoModalIsOpen,
      closeInfoModal,
      isSubmitDisabled,
    };
  },
});

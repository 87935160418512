
import { defineComponent } from '@nuxtjs/composition-api';
import TopbarLocation from './TopbarLocation.vue';
import TopbarLanguage from './TopbarLanguage.vue';

export default defineComponent({
  setup() {
    return {
      TopbarLocation,
      TopbarLanguage,
    };
  },
});

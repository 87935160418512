import Vue from 'vue';
import { devToolsPlugin } from '@webplatform/dev-tools';

export default ({ $config: { versionInfo, appEnv } }) => {
  Vue.use(devToolsPlugin, {
    consoleOnly: appEnv !== 'dev',
    buildInfo: {
      versionInfo,
    },
  });
};

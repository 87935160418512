export default {
  __name: 'UiPackery',
  props: {
  itemSelector: {
    type: String,
    default: '.item',
  },
  percentPosition: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {



return { __sfc: true, }
}

}
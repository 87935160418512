import { computed } from 'vue';


export default {
  __name: 'UiTextarea',
  props: {
  value: {
    type: [String, Number],
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  maxlength: {
    type: [String, Number],
    default: undefined,
  },
  fitContainer: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  textareaClass: {
    type: String,
    default: '',
  },
},
  emits: ['input'],
  setup(__props, { emit }) {

const props = __props;





const valueLocal = computed({
  get() {
    return props.value;
  },
  set(newValue) {
    emit('input', newValue);
  },
});

return { __sfc: true,props, emit, valueLocal }
}

}
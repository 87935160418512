import { computed, ref, provide } from 'vue';
import { onClickOutside, useToggle } from '@vueuse/core';


export default {
  __name: 'UiSelect',
  props: {
  value: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  customOptions: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
  error: {
    type: String,
    default: null,
  },
  itemId: {
    type: String,
    default: 'id',
  },
  itemText: {
    type: String,
    default: 'text',
  },
  icon: {
    type: String,
    default: '',
  },
  inputFitWidth: {
    type: Boolean,
    default: false,
  },
},
  emits: ['input'],
  setup(__props, { emit }) {

const props = __props;





const target = ref(null);
const isMenuOpen = ref(false);

const toggleMenu = useToggle(isMenuOpen);

const activeOption = computed(() =>
  props.options.find((option) => option[props.itemId] === props.value),
);

const valueLocal = computed({
  get() {
    return activeOption.value ? activeOption.value[props.itemText] : '';
  },
  set(newValue) {
    emit('input', newValue);
  },
});

const selectOption = (option) => {
  valueLocal.value = option[props.itemId];
  toggleMenu(false);
};

onClickOutside(target, () => {
  toggleMenu(false);
});

provide('selectData', {
  valueLocal,
  selectOption,
  itemId: props.itemId,
  itemText: props.itemText,
});

return { __sfc: true,props, emit, target, isMenuOpen, toggleMenu, activeOption, valueLocal, selectOption }
}

}
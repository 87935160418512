import {
  ref,
  onBeforeMount,
  onUnmounted,
  useStore,
} from '@nuxtjs/composition-api';
import { breakpointsTailwind } from '@vueuse/core';

const useBreakpoint = (screen: string) => {
  const store = useStore();
  const isBiggerThanBreakpoint = ref(store.getters['device/getIsPc']);

  const onResize = () => {
    isBiggerThanBreakpoint.value =
      window.innerWidth >=
      (breakpointsTailwind as Record<string, number>)[screen];
  };

  onBeforeMount(() => {
    onResize();
    window.addEventListener('resize', onResize, { passive: true });
  });

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', onResize, {
        passive: true,
      } as EventListenerOptions);
    }
  });

  return { isBiggerThanBreakpoint };
};

export default useBreakpoint;

import { reactive } from '@nuxtjs/composition-api';
import useOpenClose from '@/composables/useOpenClose';

interface DialogControls {
  isOpen: boolean;
  open(): void;
  close(): void;
}

const dialogs = reactive<Record<string, DialogControls>>({});

const useModalDialog = (id: string) => {
  dialogs[id] = dialogs[id] || useOpenClose();
  return dialogs[id];
};

export default useModalDialog;

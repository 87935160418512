
import { computed, defineComponent } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import ProductSeriesGallery from '~/components/sections/productSection/ProductSeriesGallery.vue';

export default defineComponent({
  components: { ProductSeriesGallery },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isBiggerSm } = useBreakpoint('sm');
    const { isBiggerThanBreakpoint: isBiggerLg } = useBreakpoint('lg');

    const similarProducts = computed(() =>
      props?.product?.similar_products?.[0].items
        .filter((item) => item.active)
        .map((item) => ({
          ...item,
          slug: `/product/${item.slug}`,
        })),
    );

    const additionalInformation = computed(() =>
      props?.product?.content.find(
        (el) => el.type === 'product-series-additional-information',
      ),
    );

    const gallery = computed(() =>
      props.product.variations
        .find((variation) => variation.sku === props.product.sku)
        ?.images.map((imageSrc) => ({
          src: imageSrc,
          alt: props.product.name,
        })),
    );

    return {
      similarProducts,
      additionalInformation,
      gallery,
      isBiggerSm,
      isBiggerLg,
    };
  },
});

import { onUnmounted, ref, Ref, useContext } from '@nuxtjs/composition-api';

interface Preloader {
  loading: Ref<boolean>;
  start(scrollToTop?: boolean): void;
  stop(): void;
}

interface PreloadersStoreItem {
  loading: Ref<boolean>;
  usage: number;
}

const preloaders: Record<string, PreloadersStoreItem> = {};

const initPreloader = (id: string) => {
  if (preloaders[id]) preloaders[id].usage++;
  else {
    preloaders[id] = {
      loading: ref(false),
      usage: 1,
    };
  }
  return preloaders[id];
};

const getPreloader = (id: string): Preloader => {
  const { ssrContext } = useContext();
  const preloader = initPreloader(id);

  const start = (scrollToTop = true) => {
    if (!ssrContext && scrollToTop)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    preloader.loading.value = true;
  };

  const stop = () => {
    preloader.loading.value = false;
  };

  onUnmounted(() => {
    preloader.usage--;
    if (!preloader.usage) delete preloaders[id];
  });

  return {
    loading: preloader.loading,
    start,
    stop,
  };
};

export default (id = 'main') => getPreloader(id);


import ProductBenefitsItem from '~/components/sections/productSection/ProductBenefitsItem.vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    ProductBenefitsItem,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
});

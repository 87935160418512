import { ref, useContext } from '@nuxtjs/composition-api';
import type { Params, Document } from '@webplatform/nuxt-cms-js-sdk';

type DocumentsQuery = Params & {
  product_sku: string;
  type?: string;
};
type DocumentsGroupedQuery = {
  product_skus: string[];
  type?: string;
};
export type DocumentItem = Document & {
  icon?: string | false;
  groups: {
    id: number;
    name: string;
  }[];
};
type DocumentsByGroup = Record<string, Document[]>;
type DocumentsBySku = Record<string, DocumentsByGroup>;
type DocumentsGroupFilter = {
  include?: string[];
  exclude?: string[];
};
type DocumentsFilters = string[] | DocumentsGroupFilter;
type DocumentsGrouping =
  | string[]
  | DocumentsFilters
  | Record<string, DocumentsFilters>;

const DOCUMENT_TYPES = {
  CERTIFICATES: 'Certificates',
  POLICIES: 'Privacy Policy',
  DRIVERS: 'Drivers',
  SOFTWARE: 'Software',
  VIDEOS: 'Video guide',
};

const splitFiltersByGroups = (filters: DocumentsGrouping) => {
  const groups: Record<string, DocumentsGroupFilter> = {};

  const addToGroup = (param: DocumentsGroupFilter, type = 'all') => {
    if (!groups[type]) groups[type] = { ...param };
    else {
      const { include, exclude } = param;
      if (include)
        groups[type].include = [...(groups[type].include ?? []), ...include];
      if (exclude)
        groups[type].exclude = [...(groups[type].exclude ?? []), ...exclude];
    }
  };

  if (Array.isArray(filters)) addToGroup({ include: filters });
  else if (typeof filters === 'object') {
    if (filters.include || filters.exclude)
      addToGroup({ ...(filters as DocumentsGroupFilter) });
    else {
      Object.entries(filters as Record<string, DocumentsFilters>).forEach(
        ([type, filter]) => {
          if (Array.isArray(filter)) addToGroup({ include: filter }, type);
          else addToGroup({ ...filter }, type);
        },
      );
    }
  }

  return groups;
};

const filterDocuments = (
  documents: DocumentsByGroup,
  grouping: DocumentsGrouping,
): DocumentsByGroup => {
  const groups = splitFiltersByGroups(grouping);
  const result: DocumentsByGroup = Object.keys(groups).reduce(
    (keys, key) => ({ ...keys, [key]: [] }),
    {},
  );

  Object.entries(documents).forEach((entry) => {
    const [group, docs] = entry;

    Object.keys(groups).forEach((groupKey) => {
      const groupFilter = groups[groupKey];
      const { include, exclude } = groupFilter;

      if (
        (include && !include.includes(group)) ||
        (exclude && exclude.includes(group))
      )
        return;

      result[groupKey].push(...docs);
    });
  });

  return result;
};

const filterDocumentsBySku = (
  documents: DocumentsBySku,
  grouping: DocumentsGrouping,
) => {
  return Object.entries(documents).reduce((items, item) => {
    const [sku, documentsSection] = item;
    const grouped = filterDocuments(documentsSection, grouping);
    return { ...items, [sku]: grouped.all };
  }, {});
};

const useFetchDocuments = () => {
  const { $api } = useContext();
  const documents = ref<DocumentsByGroup>();
  const documentsMeta = ref();

  const fetchDocuments = async (
    query: DocumentsQuery,
    grouping: DocumentsGrouping,
  ) => {
    const { data, meta } = await $api.documentsService.getAllDocuments(query);

    documents.value = filterDocuments(data, grouping);
    documentsMeta.value = meta;
  };

  const fetchDocumentsGroupedByProducts = async (
    params: DocumentsGroupedQuery,
    grouping: DocumentsGrouping = {},
  ) => {
    try {
      const result = await $api.documentsService.getDocumentsGroupedByProducts(
        params,
      );

      documents.value = filterDocumentsBySku(result.data, grouping);
    } catch (e) {}
    return {};
  };

  const fetchDocumentsGroupedBySkus = async (
    params: DocumentsGroupedQuery,
    grouping: DocumentsGrouping = {},
  ) => {
    try {
      const { data } = await $api.documentsService.getDocumentsGroupedBySku(
        params,
      );

      documents.value = filterDocumentsBySku(data, grouping);
    } catch (e) {}
    return {};
  };

  return {
    documents,
    documentsMeta,
    fetchDocuments,
    fetchDocumentsGroupedByProducts,
    fetchDocumentsGroupedBySkus,
    DOCUMENT_TYPES,
  };
};

export default useFetchDocuments;
export { DocumentsBySku, DocumentsByGroup };

import { render, staticRenderFns } from "./HeaderMainMenuMobile.vue?vue&type=template&id=67f5a57e"
import script from "./HeaderMainMenuMobile.vue?vue&type=script&lang=js"
export * from "./HeaderMainMenuMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/AROS/AROS_Deploy_Prod/components/ui/UiIcon.vue').default})

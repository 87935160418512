import { ref, watch } from '@nuxtjs/composition-api';
import { useMagicKeys } from '@vueuse/core';

const useOpenClose = () => {
  const { escape } = useMagicKeys();
  const isOpen = ref(false);

  const close = () => {
    if (isOpen.value === false) return;
    isOpen.value = false;
  };

  const open = () => {
    if (isOpen.value === true) return;
    isOpen.value = true;
  };

  watch(escape, (newValue) => {
    if (!newValue) return;
    if (isOpen.value === false) return;
    close();
  });
  return {
    isOpen,
    open,
    close,
  };
};

export default useOpenClose;


import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useWindowScroll, useWindowSize } from '@vueuse/core';

export default defineComponent({
  setup() {
    const { y: yScroll } = useWindowScroll();
    const { height: windowHeight } = useWindowSize();
    const isButtonShowed = computed(() => yScroll.value >= windowHeight.value);
    const handleClick = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };
    return {
      isButtonShowed,
      handleClick,
    };
  },
});

import {
  ref,
  InjectionKey,
  provide,
  inject,
  Ref,
  onUnmounted,
  computed,
  ComputedRef,
} from '@nuxtjs/composition-api';

type TabItem = { name: string; text: string; link?: string };

const tabsInjectionKey = Symbol('tabs') as InjectionKey<{
  tabs: Ref<ComputedRef<TabItem>[]>;
  activeTab: Ref<string>;
}>;

const scrollIntoTabNav = (target: HTMLElement) => {
  target.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  });
};

const useTabs = () => {
  const tabs = ref<ComputedRef<TabItem>[]>([]);
  const activeTab = ref<string>();

  provide(tabsInjectionKey, {
    tabs,
    activeTab,
  });

  const setActiveTab = (tabName: string, target?: HTMLElement) => {
    activeTab.value = tabName;
    target && scrollIntoTabNav(target);
  };

  return {
    tabs,
    activeTab,
    setActiveTab,
  };
};

const useTab = (tabItem: ComputedRef<TabItem>) => {
  const { tabs, activeTab } = inject(tabsInjectionKey)!;

  if (!tabs.value.length) activeTab.value = tabItem.value.name;

  tabs.value.push(tabItem);

  const isActive = computed(() => activeTab.value === tabItem.value.name);

  onUnmounted(() => {
    tabs.value = tabs.value.filter(
      (tab) => tab.value.name !== tabItem.value.name,
    );
  });

  return {
    isActive,
  };
};

export { useTabs, useTab };

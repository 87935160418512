
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { getAssetUrl } from '~/composables/useMediaAsset';

export default defineComponent({
  props: {
    image: {
      type: String,
      default: '',
    },
    video: {
      type: String,
      default: '',
    },
    videoId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => ({}),
    },
    features: {
      type: Array,
      default: () => [],
    },
    featuresText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isPlaying = ref(false);

    const playVideo = () => {
      isPlaying.value = true;
    };

    return {
      isPlaying,
      playVideo,
      getAssetUrl,
    };
  },
});

import type { Product } from '@webplatform/nuxt-cms-js-sdk';
import { ShortDescriptionBlock } from '~/composables/useFetchProducts';

const getProductContent = (product: Product): ShortDescriptionBlock => {
  let content;
  let description = '';
  let tags: string[] = [];
  let customLink = '';

  if (Array.isArray(product?.content)) {
    content = product.content[0]?.data as ShortDescriptionBlock;
  } else content = product?.content;

  if (content) {
    if (typeof content === 'string') description = content;
    else {
      description = content.description ?? '';
      tags = content.tags ?? [];
      customLink = content.customLink ?? '';
    }
  }

  return {
    description,
    tags,
    customLink,
  };
};

const getProductDescription = (product: Product): string => {
  const content: ShortDescriptionBlock | string = getProductContent(product);
  return content.description;
};

const getProductTags = (product: Product) => {
  const { tags } = getProductContent(product);
  return tags;
};

const getProductImage = (product: Product) => {
  const image = product?.variations?.[0].images?.[0];

  return !image ? null : { src: image };
};

const getProductCustomLink = (product: Product) => {
  const { customLink } = getProductContent(product);
  return customLink;
};

const getProductLink = (product: Product) => {
  return getProductCustomLink(product) || `/product/${product.slug}`;
};

export {
  getProductDescription,
  getProductImage,
  getProductTags,
  getProductCustomLink,
  getProductLink,
};

import { computed } from 'vue';


export default {
  __name: 'UiCheckbox',
  props: {
  value: {
    type: [Boolean, Array],
    default: false,
  },
  checkboxValue: {
    type: Number,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
},
  emits: ['input', 'change'],
  setup(__props, { emit }) {

const props = __props;





const valueLocal = computed({
  get() {
    return props.value;
  },
  set(newValue) {
    if (props.disabled) return;
    // eslint-disable-next-line vue/require-explicit-emits
    emit('input', newValue);
    // eslint-disable-next-line vue/require-explicit-emits
    emit('change', newValue);
  },
});

return { __sfc: true,props, emit, valueLocal }
}

}
import { render, staticRenderFns } from "./ProductContentBanner.vue?vue&type=template&id=5a09b3ce&scoped=true"
import script from "./ProductContentBanner.vue?vue&type=script&lang=ts"
export * from "./ProductContentBanner.vue?vue&type=script&lang=ts"
import style0 from "./ProductContentBanner.vue?vue&type=style&index=0&id=5a09b3ce&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a09b3ce",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductContentBannerPoints: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/AROS/AROS_Deploy_Prod/components/sections/productSection/ProductContentBannerPoints.vue').default})

const checkPublicEmailDomains = (email: string) => {
  const publicDomains = [
    'gmail.com',
    'yahoo.com',
    'mail.ru',
    'mail.com',
    'msn.com',
    'yandex.ru',
    'hotmail.com',
    'icloud.com',
  ];

  return publicDomains.some((domain) => email.includes(`@${domain}`));
};
export default checkPublicEmailDomains;


import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  setup(_props, { emit }) {
    const onChangeValue = (value) => {
      emit('input', value);
    };

    return {
      onChangeValue,
    };
  },
});

import { render, staticRenderFns } from "./AppTopbar.vue?vue&type=template&id=59ca84c6&scoped=true"
import script from "./AppTopbar.vue?vue&type=script&lang=ts"
export * from "./AppTopbar.vue?vue&type=script&lang=ts"
import style0 from "./AppTopbar.vue?vue&type=style&index=0&id=59ca84c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ca84c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopbarLocation: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/AROS/AROS_Deploy_Prod/components/TopbarLocation.vue').default,TopbarLanguage: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/AROS/AROS_Deploy_Prod/components/TopbarLanguage.vue').default})

import { computed, Ref, UnwrapRef, useContext } from '@nuxtjs/composition-api';
import { useCdnImg } from '@webplatform/cdn-image';

const isAbsolutePath = (src: string) => {
  return /^https?:\/\//.test(src);
};

export const getAssetUrl = (asset?: string, modifiers = {}): string | null => {
  const { $config } = useContext();
  const { generateUrl } = useCdnImg();

  const isVideo = (src: string) => /(.)+\.(mp4|svg)$/.test(`${src}`);

  const generateVideoUrl = (src: string) => {
    return `${$config.fullPath}${src}`;
  };

  if (!asset) return null;
  if (!isVideo(asset)) return generateUrl(asset, modifiers);

  return isAbsolutePath(asset) ? asset : generateVideoUrl(asset);
};

export const useMediaAsset = (
  asset: Ref<string>,
  modifiers = {},
): Ref<UnwrapRef<string>> | Ref<UnwrapRef<null>> => {
  return computed(() => getAssetUrl(asset.value, modifiers));
};

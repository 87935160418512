import 'vanilla-cookieconsent/src/cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './cookie-consent.css';
import { COOKIE_DEFAULT_SETTINGS, CATEGORIES } from './constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default async ({
  $gtm,
  $api: { pagesService },
  $config: { gtmId },
  app: { store },
}) => {
  const GTM_ID = gtmId;
  const configGtm = store.getters['config/getGTM'];

  if (!GTM_ID && !configGtm) return;
  // Define dataLayer and the gtag function.
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-next-line prefer-rest-params, no-undef
    dataLayer.push(arguments);
  }

  gtag('consent', 'default', {
    analytics_storage: 'denied',
    ad_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
  });
  const currentGtm = configGtm || GTM_ID;
  $gtm.init(currentGtm);

  const cookieConsent = window.initCookieConsent();
  try {
    const { data } = await pagesService.getPageBySlug('cookie-banner');
    const cookieConfig = data.blocks[0].content;
    cookieConsent.run({
      revision: 1,
      onAccept: () => {
        CATEGORIES.forEach((category) => {
          if (cookieConsent.allowedCategory(category)) {
            if (category !== 'advertisment')
              gtag('consent', 'update', {
                [category]: 'granted',
              });
            else {
              ['ad_storage', 'ad_user_data', 'ad_personalization'].forEach(
                (nc) => {
                  gtag('consent', 'update', {
                    [nc]: 'granted',
                  });
                },
              );
            }
          }
        });
      },
      gui_options: {
        consent_modal: {
          layout: 'cloud',
        },
      },
      languages: {
        en: cookieConfig || COOKIE_DEFAULT_SETTINGS,
      },
    });
  } catch (_) {}
};

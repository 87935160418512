
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const onChangeValue = (value) => {
      if (!value) emit('close');
    };

    return {
      onChangeValue,
    };
  },
});

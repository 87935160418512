import {
  computed,
  ref,
  useContext,
  useRoute,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import useFetchDocuments, {
  DocumentsByGroup,
} from '@/composables/useFetchDocuments';
import useFetchProducts from '@/composables/useFetchProducts';
import type { Response, Product, Document } from '@webplatform/nuxt-cms-js-sdk';
import usePagePreloader from '@/composables/usePagePreloader';

type DocsSearchResult = Pick<Product, 'id' | 'name' | 'slug'> & {
  image: string;
  documents: Document[];
};

const prepareDocsResults = (
  products: Product[],
  docs: DocumentsByGroup,
): DocsSearchResult[] => {
  return products
    .map((product) => {
      const { id, sku, name, slug, variations } = product;
      return {
        id,
        name,
        slug,
        image: variations.find((vr: any) => vr.sku === sku)?.images[0],
        documents: docs[product.sku],
      };
    })
    .filter(Boolean) as DocsSearchResult[];
};

const getPopularQueries = (translation: string) =>
  String(translation || '')
    .split(';')
    .map((item) => item.trim())
    .filter(Boolean);

const useSearch = () => {
  const { i18n } = useContext();
  const router = useRouter();
  const route = useRoute();
  const { fetchProductsByName } = useFetchProducts();
  const { documents, DOCUMENT_TYPES, fetchDocumentsGroupedByProducts } =
    useFetchDocuments();

  const preloader = usePagePreloader('search');
  const pageMeta = ref();
  const searchResults = ref();
  const searchValue = ref('');
  const popularSearches = ref<string[]>(
    getPopularQueries(i18n.t('popular_queries_items') as string),
  );
  const searchQuery = computed(() => route.value.query.search);
  const currentPage = computed(() => route.value.query.page);
  const totalCount = computed(() => pageMeta.value?.total ?? 0);
  const pagesCount = computed(() => pageMeta.value?.last_page ?? 1);
  const searchType = computed(() =>
    route.value.path.startsWith('/search')
      ? route.value.path.split('/').pop()
      : '',
  );

  const updateRouter = (type: string) => {
    router.push({
      path: `/search/${type}`,
      query: {
        search: searchValue.value,
        page: currentPage.value,
      },
    });
  };

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const clearResults = () => {
    searchResults.value = null;
    pageMeta.value = null;
  };

  const getProducts = async (
    queryString: string,
  ): Promise<Response<Product[]> | undefined> => {
    // eslint-disable-next-line no-return-await
    return await fetchProductsByName(queryString, {
      // @ts-ignore
      limit: 12,
      page: currentPage.value as any,
    });
  };

  const searchProducts = async () => {
    scrollUp();
    clearResults();
    updateRouter('products');

    if (!searchValue.value) return;

    try {
      preloader.start();
      const result = await getProducts(searchValue.value);

      searchResults.value = result?.data;
      pageMeta.value = result?.meta;
    } finally {
      preloader.stop();
    }
  };

  const searchDocuments = async () => {
    scrollUp();
    clearResults();
    updateRouter('documents');

    if (!searchValue.value) return;

    try {
      preloader.start();
      const products = await getProducts(searchValue.value);

      if (products?.meta.total) {
        await fetchDocumentsGroupedByProducts(
          { product_skus: products.data.map((product: any) => product.sku) },
          { exclude: [DOCUMENT_TYPES.VIDEOS] },
        );

        if (documents.value) {
          searchResults.value = prepareDocsResults(
            products.data,
            documents.value,
          );
          pageMeta.value = products.meta;
        }
      }
    } finally {
      preloader.stop();
    }
  };

  watch(searchQuery, (value) => {
    searchValue.value = value as string;
  });

  return {
    preloader,
    pageMeta,
    searchResults,
    searchQuery,
    searchValue,
    currentPage,
    totalCount,
    pagesCount,
    popularSearches,
    searchType,
    searchProducts,
    searchDocuments,
    updateRouter,
  };
};

export default useSearch;


import { defineComponent } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import { getAssetUrl } from '~/composables/useMediaAsset';

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    return {
      isDesktop,
      getAssetUrl,
    };
  },
});


import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
});

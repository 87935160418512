import Vue, { ref, onMounted, computed, watch } from 'vue';
import { getGoogleMapsAPI } from 'gmap-vue';


export default {
  __name: 'UiMap',
  props: {
  markers: {
    type: Array,
    default() {
      return [];
    },
  },
  activeMarker: {
    type: Number,
    default: null,
  },
},
  emits: ['update:activeMarker'],
  setup(__props, { emit }) {

const props = __props;

const ZOOM = 17;
const MARKER_IMAGE = '/images/marker.png';
const MARKER_ACTIVE_IMAGE = '/images/marker-active.png';





const map = ref(null);
const google = computed(() => getGoogleMapsAPI());
const fitMarkersBounds = (m, markers) => {
  const bounds = new google.value.maps.LatLngBounds();
  markers.forEach((marker) => {
    bounds.extend(new google.value.maps.LatLng(marker.position));
  });
  m.fitBounds(bounds);
  if (markers.length < 2) m.setZoom(ZOOM);
};
onMounted(async () => {
  await Vue.$gmapApiPromiseLazy();
  fitMarkersBounds(map.value.$mapObject, props.markers);
});

watch(
  () => props.markers,
  () => {
    fitMarkersBounds(map.value.$mapObject, props.markers);
  },
);

const center = ref({ lat: 0, lng: 0 });

const setCenter = (index) => {
  emit('update:activeMarker', index);
};

const getMarkerIcon = (index) => {
  return props.activeMarker === index ? MARKER_ACTIVE_IMAGE : MARKER_IMAGE;
};

watch(
  () => props.activeMarker,
  (value) => {
    if (value === null) return;
    map.value.$mapObject.panTo(props.markers[value].position);
    map.value.$mapObject.setZoom(ZOOM);
  },
);

return { __sfc: true,ZOOM, MARKER_IMAGE, MARKER_ACTIVE_IMAGE, props, emit, map, google, fitMarkersBounds, center, setCenter, getMarkerIcon }
}

}

import { computed, defineComponent } from '@nuxtjs/composition-api';
import { getAssetUrl } from '~/composables/useMediaAsset';

export default defineComponent({
  props: {
    image: {
      type: String,
      default: '',
    },
    video: {
      type: String,
      default: '',
    },
    videoId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => ({}),
    },
    features: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const videoSrc = computed(() => getAssetUrl(props.video));
    const playVideo = () => {
      emit('play', props.videoId);
    };

    return {
      videoSrc,
      getAssetUrl,
      playVideo,
    };
  },
});

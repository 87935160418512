import { computed, inject } from 'vue';


export default {
  __name: 'UiSelectOption',
  props: {
  option: {
    type: Object,
    default: () => ({}),
  },
  disableSelect: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;



const {
  valueLocal: selectValue,
  selectOption,
  itemId,
  itemText,
} = inject('selectData');

const isActive = computed(() => props.option[itemId] === selectValue.value);

const handleSelectOption = (option) => {
  selectOption(option);
};

return { __sfc: true,props, selectValue, selectOption, itemId, itemText, isActive, handleSelectOption }
}

}
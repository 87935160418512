
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
});


import { computed, defineComponent } from '@nuxtjs/composition-api';
import ProductFeatures from '~/components/sections/productSection/ProductFeatures.vue';
import ProductUserHelp from '~/components/sections/productSection/ProductUserHelp.vue';
import ProductSoftware from '~/components/sections/productSection/ProductSoftware.vue';

export default defineComponent({
  components: {
    ProductFeatures,
    ProductUserHelp,
    ProductSoftware,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const softwareDocs = computed(() => props.product?.softwareDocuments);

    const isShowUserHelp = computed(
      () =>
        props.product.documents.manuals.length > 0 ||
        props.product.documents.videos.length > 0 ||
        props.product.faqs.length > 0,
    );

    const software = computed(() =>
      props.product?.content.find(
        (item: { type: string }) => item.type === 'software-documents',
      ),
    );

    return {
      softwareDocs,
      isShowUserHelp,
      software,
    };
  },
});

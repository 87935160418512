import { render, staticRenderFns } from "./HtmlContentBlock.vue?vue&type=template&id=2eeda0b8"
import script from "./HtmlContentBlock.vue?vue&type=script&lang=js"
export * from "./HtmlContentBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
    isActive: {
      type: Boolean,
    },
    isParent: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
  localeChanged,
} from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, alpha_spaces } from 'vee-validate/dist/rules';
import checkPublicEmailDomains from '@/helpers/checkPublicEmailDomains';

extend('required', required);
extend('email', email);
extend('public_email', {
  validate: (value) => !checkPublicEmailDomains(value),
});
extend('alpha_spaces', alpha_spaces);
extend('phone', {
  validate: (value) => /^\+?\d{8,}$/.test(value),
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

export default ({ app }) => {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = app.i18n.t(`${field}`);
      return app.i18n.t(`validation_${values._rule_}`, values);
    },
  });
  app.i18n.onLanguageSwitched = () => {
    localeChanged();
  };
};

import Vue from 'vue';
import { provideOptions } from '@webplatform/cdn-image';

export default function cdnImagePlugin({ store, $config }) {
  provideOptions(Vue, {
    get baseURL() {
      return store.getters['config/getCDNUrl'] || $config.cdnURL;
    },
  });
}


import {
  computed,
  defineComponent,
  ref,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';

import { useElementHover } from '@vueuse/core';
import HeaderMainMenuItem from '~/domains/menus/components/HeaderMainMenuItem';

export default defineComponent({
  components: { HeaderMainMenuItem },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/require-prop-types
    menuWrapperRef: {
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const menu = computed(() => props.menuWrapperRef);
    const isHovered = useElementHover(menu);
    const activeItem = ref(null);

    const setActiveItem = (id) => {
      activeItem.value = id;
    };

    watch(isHovered, (value) => {
      if (value === false) activeItem.value = null;
    });

    watch(route, () => {
      activeItem.value = null;
    });

    watch(activeItem, (value) => {
      emit('toggle-menu', Boolean(value));
    });

    return { activeItem, setActiveItem };
  },
});

import { ActionTree } from 'vuex';

export const strict = false;

export const state = () => ({});

export type RootState = ReturnType<typeof state>;

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit({ dispatch }, { $api }) {
    await dispatch('config/load', { $api });
    await dispatch('menus/load', { $api });
  },
};

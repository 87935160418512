import { render, staticRenderFns } from "./UiPackeryItem.vue?vue&type=template&id=0ddc9784"
import script from "./UiPackeryItem.vue?vue&type=script&setup=true&lang=js"
export * from "./UiPackeryItem.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
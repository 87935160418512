import { computed } from 'vue';


export default {
  __name: 'UiSectionTitle',
  props: {
  title: {
    type: [String, Object],
    default: '',
  },
  tag: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;



const tagEl = computed(() => {
  if (props.title?.tag && /^div|span|h[1-6]$/i.test(props.title.tag))
    return props.title.tag;

  return props.tag || 'h2';
});

const text = computed(() =>
  typeof props.title === 'string' ? props.title : props.title?.text,
);

return { __sfc: true,props, tagEl, text }
}

}
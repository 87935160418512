
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    crumbs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const hideLastCrumb = computed(() => Number(props.crumbs?.length) > 2)
    const isLast = (i) => i === Number(props.crumbs?.length) - 1;

    return { hideLastCrumb, isLast };
  }
});


import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    spinnerOffsetTop: {
      type: [String, Number],
      default: 0,
    },
    minHeight: {
      type: [String, Number],
      default: 0,
    },
    fullHeight: {
      type: Boolean,
      default: true,
    },
    background: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const preloaderStyles = computed(() => {
      const styles = {};

      if (+props.minHeight)
        styles['min-height'] = `${Number(props.minHeight)}px`;

      if (+props.spinnerOffsetTop)
        styles['padding-top'] = `${Number(props.spinnerOffsetTop)}px`;

      return styles;
    });

    return { preloaderStyles };
  },
});

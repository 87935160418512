
import { defineComponent } from '@nuxtjs/composition-api';
import { getAssetUrl } from '~/composables/useMediaAsset';
import ProductContentBannerPoints from '~/components/sections/productSection/ProductContentBannerPoints.vue';

export default defineComponent({
  components: { ProductContentBannerPoints },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      getAssetUrl,
    };
  },
});

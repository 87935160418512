
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
    isInnerCategory: {
      type: Boolean,
    },
    areLastChildren: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const hasChildren = computed(() =>
      Boolean(props.menuItem.children?.length),
    );

    const openCategory = () => {
      emit('open-category');
    };

    const closeMenu = () => {
      emit('close-menu');
    };

    const paddingClasses = computed(() => {
      if (props.isInnerCategory && !hasChildren.value) return 'py-4';
      if (!props.isInnerCategory && !hasChildren.value) return 'py-6';
      return '';
    });

    return {
      hasChildren,
      openCategory,
      closeMenu,
      paddingClasses,
    };
  },
});


import { defineComponent, ref, watch } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    name: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    phones: {
      type: Array,
      default: () => [],
    },
    workingHours: {
      type: Array,
      default: () => [],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const serviceElement = ref();
    const formatTime = (time, index) => {
      return `${index > 0 ? '-' : ''} ${time.start}-${time.end}`;
    };

    watch(
      () => props.isActive,
      (value) => {
        if (!value) return;
        serviceElement.value.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      },
    );

    return {
      serviceElement,
      formatTime,
    };
  },
});

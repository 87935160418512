import { ref, useContext } from '@nuxtjs/composition-api';
import type { Category } from '@webplatform/nuxt-cms-js-sdk';

type CategoryExtended = Category & {
  branch?: Category[];
  parent_id?: number;
};

type CategoryNavItem = {
  id: number;
  slug: string;
  text?: string;
};

const addAllCategoriesLink = (
  category: CategoryExtended,
  items: CategoryExtended[] = [],
) => {
  const firstItem: Partial<CategoryNavItem> = {};

  if (!category.parent_id) {
    firstItem.id = category.id;
    firstItem.slug = category.slug;
  } else {
    const parentCat = category.branch![category.branch!.length - 1];
    firstItem.id = parentCat.id;
    firstItem.slug = parentCat.slug;
  }

  return [firstItem, ...items];
};

const useCatalogNav = () => {
  const { $api } = useContext();
  const catalogNavItems = ref<Partial<CategoryNavItem>[]>();

  const getCatalogNav = async (category: CategoryExtended) => {
    if (!category) catalogNavItems.value = [];
    else if (category.children?.length)
      catalogNavItems.value = addAllCategoriesLink(category, category.children);
    else {
      try {
        const { data } = await $api.categoriesService.getCategoryBySlug(
          // @ts-ignore
          category.parent_id,
          {
            includes: ['children'],
          },
        );

        catalogNavItems.value = addAllCategoriesLink(category, data.children);
      } catch (e) {}
    }
  };

  return { catalogNavItems, getCatalogNav };
};

export default useCatalogNav;


import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  useContext,
} from '@nuxtjs/composition-api';
import { useFetchServiceCenters } from '@webplatform/nuxt-cms-js-sdk';

export default defineComponent({
  setup() {
    const {
      $api: { serviceCentersService, countriesService, citiesService },
    } = useContext();
    const countries = ref([]);
    const cities = ref([]);
    const selectedCountry = ref(null);
    const selectedCity = ref(null);
    const activeMarker = ref(null);
    const citiesLoading = ref(false);
    const setActiveMarker = (i) => {
      activeMarker.value = i;
    };

    const { centers, fetchServiceCenters } = useFetchServiceCenters({
      limit: 9999,
    });

    const fetchCountries = async () => {
      try {
        const { data: servicesData } =
          await serviceCentersService.getServicesCountriesIds();
        const { data: countriesData } =
          await countriesService.getCountriesByIds({
            country_id: servicesData.map((s) => s.id),
            limit: 1000,
          });
        countries.value = countriesData;
      } catch (error) {}
    };

    const fetchCities = async (countryId) => {
      try {
        citiesLoading.value = true;
        const { data: servicesData } =
          await serviceCentersService.getServicesCitiesByCountryId(countryId);
        const { data: citiesData } = await citiesService.getCitiesByIds({
          city_id: servicesData.map((s) => s.id),
        });
        cities.value = citiesData;
      } catch (error) {
        console.log(error);
      } finally {
        citiesLoading.value = false;
      }
    };

    const filterHours = (hours) => {
      return hours.filter((hour) => hour.start !== null && hour.end !== null);
    };

    const serviceCenters = computed(() => {
      const result = [];
      centers.value.forEach((s) => {
        s.addresses.forEach((address) => {
          result.push({
            name: s.name,
            url: address.url,
            address: address.address,
            phones: address.phones,
            lat: Number(address.lat),
            lng: Number(address.lon),
            countryId: address.country_id,
            cityId: address.city_id,
            working_hours: filterHours(address.working_hours),
          });
        });
      });

      return result;
    });

    const citiesSelectDisable = computed(
      () =>
        selectedCountry.value === null ||
        citiesLoading.value ||
        (!citiesLoading.value && !cities.value.length),
    );

    const filteredServiceCenters = computed(() => {
      let result = [];
      if (selectedCountry.value !== null && !selectedCity.value) {
        result = serviceCenters.value.filter(
          (s) => selectedCountry.value === s.countryId,
        );
      } else if (selectedCity.value !== null) {
        result = serviceCenters.value.filter(
          (s) => selectedCity.value === s.cityId,
        );
      } else {
        result = serviceCenters.value;
      }
      return result;
    });

    const activeService = computed(
      () => filteredServiceCenters.value[activeMarker.value],
    );

    const markers = computed(() =>
      filteredServiceCenters.value.map((s) => {
        return {
          title: s.name,
          position: {
            lat: s.lat,
            lng: s.lng,
          },
        };
      }),
    );

    watch(selectedCountry, (newValue) => {
      selectedCity.value = null;
      activeMarker.value = null;
      fetchCities(newValue);
    });

    watch(selectedCity, () => {
      activeMarker.value = null;
    });

    onMounted(() => {
      fetchServiceCenters();
      fetchCountries();
    });

    return {
      cities,
      countries,
      selectedCountry,
      selectedCity,
      markers,
      centers,
      activeMarker,
      setActiveMarker,
      filteredServiceCenters,
      citiesSelectDisable,
      fetchCities,
      activeService,
    };
  },
});

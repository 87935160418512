import { useContext } from '@nuxtjs/composition-api';
import type {
  Params,
  Response,
  Faq,
  Product,
} from '@webplatform/nuxt-cms-js-sdk';
import { DocumentItem } from '@/composables/useFetchDocuments';

type FetchProductsParams = Params & {
  type?: string;
  page?: string | (string | null)[];
  search?: string;
  searchFields?: string;
  orderBy?: string;
  sortedBy?: string;
  limit?: string;
};
type ProductFeatureItem = {
  feature_value: string;
  feature_value_en: string;
  slug: string;
  selected: boolean;
  active: boolean;
  color: string;
};
type ProductFeatureGroup = {
  feature_id: number | string;
  feature_name: string;
  feature_name_en: string;
  feature_type: number;
  items: ProductFeatureItem[];
};
type ShortDescriptionBlock = {
  description: string;
  tags?: string[];
  customLink?: string;
};
type ProductContent = {
  lang: string | null;
  type: string;
  data: string | ShortDescriptionBlock;
};
interface ProductExtended extends Product {
  similar_products?: ProductFeatureGroup[];
  faqs?: Faq[];
  documents?: Record<string, DocumentItem[]>;
  description?: string;
  image?: { src: string };
  tags?: string[];
}

const useFetchProducts = () => {
  const { $api } = useContext();

  const fetchProductsAll = async (
    params: FetchProductsParams,
    // eslint-disable-next-line consistent-return
  ): Promise<Response<Product[]> | undefined> => {
    try {
      const { data, meta } = (await $api.productsService.getAllProducts(
        params,
      )) as unknown as Response<Product[]>;

      return { data, meta };
    } catch (e) {}
  };

  const fetchProductBySlug = async (
    slug: string,
    params: Params,
    // eslint-disable-next-line consistent-return
  ): Promise<ProductExtended | undefined> => {
    try {
      const { data } = await $api.productsService.getProductBySlug(
        slug,
        params,
      );

      return data as ProductExtended;
    } catch (e) {}
  };

  const fetchProductsByIds = async (
    productIds: (string | number)[],
    params: Params = {},
    // eslint-disable-next-line consistent-return
  ): Promise<Product[] | undefined> => {
    try {
      const response = (await $api.productsService.getAllProducts({
        ...params,
        ...{
          search: `id:${productIds.map((id) => `${id}`).join(',')}`,
          searchFields: 'id:in',
          limit: 0,
        },
      })) as unknown as Response<Product[]>;

      return response?.data;
    } catch (e) {}
  };

  const fetchProductsByName = async (
    queryName: string,
    params: FetchProductsParams = {},
    // eslint-disable-next-line consistent-return
  ): Promise<Response<Product[]> | undefined> => {
    try {
      // @ts-ignore
      const { data, meta } = (await $api.productsService.getAllProducts({
        ...params,
        ...{
          search: `name:${queryName};sku:${queryName}`,
          searchFields: 'name:ilike;sku:ilike',
          orderBy: 'name',
        },
      })) as unknown as Response<Product[]>;

      return { data, meta };
    } catch (e) {}
  };

  return {
    fetchProductsAll,
    fetchProductsByIds,
    fetchProductBySlug,
    fetchProductsByName,
  };
};

export default useFetchProducts;

export { ProductExtended, ProductContent, ShortDescriptionBlock };

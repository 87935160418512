
import { computed, defineComponent } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import { getAssetUrl } from '~/composables/useMediaAsset';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const backgroundImage = computed(() =>
      getAssetUrl(
        isDesktop.value
          ? props.data.image
          : props.data.imageMobile ?? props.data.image,
      ),
    );

    return {
      getAssetUrl,
      backgroundImage,
    };
  },
});

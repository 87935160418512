
import { defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api';

import FooterMainMenuItem from '@/domains/menus/components/FooterMainMenuItem.vue';

export default defineComponent({
  components: {
    FooterMainMenuItem,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const route = useRoute();
    const activeItem = ref(null);

    watch(
      route,
      () => {
        activeItem.value = null;
      },
      { immediate: true },
    );

    return { activeItem };
  },
});

export default function isTouchDevice() {
  try {
    return window
      ? 'ontouchstart' in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
      : false;
  } catch (err) {
    return false;
  }
}

import { render, staticRenderFns } from "./ProductSeriesGallery.vue?vue&type=template&id=279e569b"
import script from "./ProductSeriesGallery.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductSeriesGallery.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
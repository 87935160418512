
import {
  computed,
  defineComponent,
  useContext,
  useStore,
} from '@nuxtjs/composition-api';

import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppTopbar from '@/components/AppTopbar.vue';
import { AppMenu } from 'store/menus';
import usePagePreloader from '@/composables/usePagePreloader';
import AppUpButton from '@/components/AppUpButton.vue';

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
    AppUpButton,
    AppTopbar,
  },
  setup() {
    const { route } = useContext();
    const store = useStore();
    const pagePreloader = usePagePreloader();

    const isHomepage = computed(() => route.value?.fullPath === '/');

    const menuTop: AppMenu[] = store.getters['menus/menuTop'];
    const menuMain: AppMenu[] = store.getters['menus/menuMain'];
    const menuFooter: AppMenu[] = store.getters['menus/menuFooter'];
    const menuBottom: AppMenu[] = store.getters['menus/menuBottom'];

    return {
      isHomepage,
      menuTop,
      menuMain,
      menuFooter,
      menuBottom,
      pagePreloader,
    };
  },
  // @ts-ignore
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const { googleGtagId } = this.$config;
    const scripts = [];
    const store = this.$store;
    const favicon = store.getters['config/getFavicon'];

    if (googleGtagId)
      scripts.push({
        src: `https://www.googletagmanager.com/gtag/js?id=${googleGtagId}`,
        async: true,
      });

    return {
      title: 'Prestigio solutions',
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        class: 'theme-ar',
      },
      meta: [...i18nHead.meta],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: favicon ?? '/favicon.ico' },
        ...i18nHead.link,
      ],
      script: scripts,
    };
  },
});

/* eslint-disable no-shadow */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from 'store/index';

interface AppMenuItem {
  id: string;
  url?: string;
  name: string;
  image?: {
    id: string;
    name: string;
    path: string;
  };
  children?: AppMenuItem[];
}

export type AppMenu = AppMenuItem[];

export const state = () => ({
  menuTop: [] as AppMenu,
  menuMain: [] as AppMenu,
  menuFooter: [] as AppMenu,
  menuBottom: [] as AppMenu,
});

type MenusState = ReturnType<typeof state>;

export const getters: GetterTree<MenusState, RootState> = {
  menuTop: (state) => state.menuTop,
  menuMain: (state) => state.menuMain,
  menuFooter: (state) => state.menuFooter,
  menuBottom: (state) => state.menuBottom,
};

export const mutations: MutationTree<MenusState> = {
  setMenus(state, menus) {
    state.menuTop = menus.menu_header_1 ? menus.menu_header_1[0].data : [];

    state.menuMain = menus.menu_header_2 ? menus.menu_header_2[0].data : [];

    state.menuFooter = menus.menu_footer ? menus.menu_footer[0].data : [];

    state.menuBottom = menus.menu_footer_2 ? menus.menu_footer_2[0].data : [];
  },
};

export const actions: ActionTree<MenusState, RootState> = {
  async load({ commit }, { $api }) {
    try {
      const { data } = await $api.menusService.getMenusByKeys({
        key: ['menu_header_1', 'menu_header_2', 'menu_footer', 'menu_footer_2'],
      });
      commit('setMenus', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
};

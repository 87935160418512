
import { defineComponent } from '@nuxtjs/composition-api';
import { getAssetUrl } from '~/composables/useMediaAsset';
import useBreakpoint from '~/composables/useBreakpoint';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { isBiggerThanBreakpoint: isBiggerSm } = useBreakpoint('sm');

    const getIcon = (item) =>
      getAssetUrl(isBiggerSm.value ? item.icon?.desktop : item.icon?.mobile);

    return {
      getIcon,
    };
  },
});

import Vue from 'vue';
import GmapVue, { components } from 'gmap-vue';

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyAbCn9nZdFPID4PfE2nCEP9yngRpmB1U-s',
    libraries: 'places',
  },
  installComponents: true,
  dynamicLoad: false,
});

Vue.component('GmapCluster', components.Cluster);


import {
  computed,
  defineComponent,
  ref,
  useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { $config } = useContext();

    const limit = ref(5);
    const showAll = ref(false);
    const hiddenItems = computed(() =>
      Math.max(0, (props.items?.length || 0) - limit.value),
    );

    const filterLink = (path: string) => {
      const isExternalLink = /^https?:/.test(path);

      if (isExternalLink) {
        return path;
      }

      return `${$config.fullPath}${path}`;
    };

    const showAllItems = () => {
      showAll.value = true;
    };

    return {
      limit,
      showAll,
      hiddenItems,
      filterLink,
      showAllItems,
    };
  },
});


import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { onClickOutside, useScroll } from '@vueuse/core';
import { useCookies } from '@/node_modules/@vueuse/integrations/useCookies';
import useOpenClose from '@/composables/useOpenClose';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isOpen, open, close } = useOpenClose();
    const popup = ref();
    const cookies = useCookies(['book-meeting']);
    const bmCookies = cookies.get('book-meeting') ?? {};

    const setCookies = (key: string, value: any) => {
      cookies.set('book-meeting', { ...bmCookies, [key]: value });
    };

    const bookMeeting = () => {
      if (!bmCookies.isBooked) setCookies('isBooked', true);

      const { button } = props.data.popup;

      if (button?.link) window.open(button.link, button.target || '_blank');

      close();
    };

    const showPopupOnScroll = () => {
      const triggerEl = document?.getElementById('section-awards');
      if (!triggerEl) return;

      const { y } = useScroll(window);

      const unwatch = watch(y, (value) => {
        if (value < triggerEl?.offsetTop) return;

        setCookies('init', true);
        unwatch();
        open();
      });
    };

    onClickOutside(popup as any, () => close());

    onMounted(() => {
      if (!bmCookies.init && !bmCookies.isBooked) showPopupOnScroll();
    });

    return {
      isOpen,
      open,
      bookMeeting,
    };
  },
});

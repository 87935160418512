import { ref } from '@nuxtjs/composition-api';

type BreadcrumbItem = {
  text: string;
  link?: string;
};

type BreadcrumbItemParam = string | BreadcrumbItem;

const predefinedCrumbs: Record<string, BreadcrumbItem> = {
  main: { text: 'home_page', link: '/' },
  categories: { text: 'categories' },
  catalog: { text: 'catalog', link: '/catalog' },
  search: { text: 'searching_results' },
};

const useBreadcrumbs = () => {
  const breadcrumbs = ref<BreadcrumbItem[]>([predefinedCrumbs.main]);

  const prepareCrumb = (item: BreadcrumbItemParam): BreadcrumbItem =>
    typeof item === 'string' ? predefinedCrumbs[item] ?? { text: item } : item;

  const applyCrumbs = (items: BreadcrumbItemParam[], rewrite = false) => {
    const newItems: BreadcrumbItem[] = items.map(prepareCrumb);

    rewrite
      ? (breadcrumbs.value = newItems)
      : breadcrumbs.value.push(...newItems);
  };

  const addCrumbs = (...items: BreadcrumbItemParam[]) => applyCrumbs(items);

  const setCrumbs = (...items: BreadcrumbItemParam[]) =>
    applyCrumbs(items, true);

  return {
    breadcrumbs,
    addCrumbs,
    setCrumbs,
  };
};

export default useBreadcrumbs;

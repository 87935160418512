
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },

    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    countType: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const valueLocal = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        // eslint-disable-next-line vue/require-explicit-emits
        emit('input', newValue);
      },
    });
    const autocomplete = ref();

    return {
      valueLocal,
      autocomplete,
    };
  },
});


import { defineComponent, computed, useStore } from '@nuxtjs/composition-api';
import { AppMenu } from 'store/menus';

export default defineComponent({
  setup() {
    const store = useStore();
    const menuTop: AppMenu = store.getters['menus/menuTop'];

    const options = computed(() =>
      menuTop.map((el) => ({
        name: el.name,
        value: el.url,
      })),
    );
    const currentPage = computed(() => {
      if (!process.client) return '';
      return options.value.find(
        (option) => option.value === window.location.origin,
      )?.value;
    });

    const handleSelect = (e: any) => {
      window.location.replace(e);
    };
    return {
      options,
      currentPage,
      handleSelect,
    };
  },
});

import { render, staticRenderFns } from "./FooterMainMenu.vue?vue&type=template&id=540c202c"
import script from "./FooterMainMenu.vue?vue&type=script&lang=js"
export * from "./FooterMainMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
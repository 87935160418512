
import {
  computed,
  defineComponent,
  ref,
  useContext,
  watch,
} from '@nuxtjs/composition-api';

import { useElementHover } from '@vueuse/core';

export default defineComponent({
  components: {},
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
    isActive: {
      type: Boolean,
    },
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      $img,
      $config: { joinuApp: isJoinuApp },
    } = useContext();
    const menuLink = ref();
    const isHoveredLink = useElementHover(menuLink);
    const activeCategoryId = ref(null);
    const activeCategoryName = ref(null);
    const activeCategoryImage = ref(null);
    const activeCategoryChildren = ref(null);

    const hasChildren = computed(() =>
      Boolean(props.menuItem.children?.length),
    );

    const hasImages = computed(
      () =>
        hasChildren.value && props.menuItem.children.some((item) => item.image),
    );

    const setActiveCategory = (category) => {
      activeCategoryId.value = category?.id ?? null;

      activeCategoryName.value = category?.name ?? null;

      activeCategoryChildren.value =
        category?.children && category?.children.length
          ? category?.children
          : null;

      activeCategoryImage.value = category?.image?.path
        ? `url(${$img(category.image.path, {}, { provider: 'imgproxy' })})`
        : null;
    };
    const menuItemTextClass = computed(() =>
      props.dark || props.isMenuOpen || isJoinuApp
        ? 'text-text-primary'
        : 'text-global-white',
    );

    watch(isHoveredLink, (value) => {
      emit('set-active-item');
      if (!value) setActiveCategory();
    });

    return {
      menuLink,
      hasChildren,
      hasImages,
      activeCategoryId,
      activeCategoryImage,
      setActiveCategory,
      activeCategoryChildren,
      activeCategoryName,
      menuItemTextClass,
    };
  },
});


import {
  defineComponent,
  ref,
  useStore,
  computed,
} from '@nuxtjs/composition-api';

import HeaderMainMenuMobileItem from '@/domains/menus/components/HeaderMainMenuMobileItem';
// import SocialsList from '@/components/SocialsList';

export default defineComponent({
  components: {
    HeaderMainMenuMobileItem,
    // SocialsList,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(_props, { emit }) {
    const store = useStore();
    const socialNetworks = store.getters['config/getSocials'];

    const activeCategory = ref(null);
    const activeCategoryList = ref([]);

    const openCategory = (category) => {
      activeCategoryList.value.push(category);
      activeCategory.value = category;
    };

    const closeCategory = () => {
      if (activeCategoryList.value.length) {
        const lastIndex = activeCategoryList.value.length - 1;
        // Delete last category
        activeCategoryList.value.pop();
        activeCategory.value = activeCategoryList.value[lastIndex - 1];
      } else {
        activeCategory.value = null;
      }
    };

    const closeMenu = () => {
      activeCategory.value = null;
      activeCategoryList.value = [];
      emit('toggle-menu');
    };

    const areLastChildren = computed(() => {
      return activeCategory.value.children.every((child) => !child.children);
    });

    return {
      socialNetworks,
      activeCategory,
      activeCategoryList,
      openCategory,
      closeCategory,
      closeMenu,
      areLastChildren,
    };
  },
});

import { ref, useContext } from '@nuxtjs/composition-api';
import type { Params, Category } from '@webplatform/nuxt-cms-js-sdk';
import useCatalogNav from '@/domains/catalog/composables/useCatalogNav';
import getCategoryAggs from '@/domains/categories/helpers/getCategoryAggs';

type RouteQueryParams = {
  [key: string]: string | (string | null)[] | null | undefined;
};
type FetchCategoryParams = Params & {
  limit: number;
};

type CategoryExtended = Category & Record<string, any>;

const getPageAggs = (query: RouteQueryParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { page, ...rest } = query;
  const aggs: Record<string, any> = {
    filters: {},
    sorting: {},
    limit: 15,
  };

  Object.entries(rest).forEach(([key, value]) => {
    if (key.startsWith('order')) aggs.sorting[key] = value;
    else aggs.filters[key] = typeof value === 'string' ? [value] : value;
  });

  return aggs;
};

const useFetchCategory = () => {
  const { error, ssrContext, $api } = useContext();
  const category = ref<CategoryExtended>();
  const categoryAggs = ref();
  const categoryHeader = ref();
  const categoryContent = ref();
  const categoryProducts = ref();
  const pagesCount = ref();
  const { catalogNavItems, getCatalogNav } = useCatalogNav();

  const fetchCategory = async (
    slug: string,
    aggsQuery: FetchCategoryParams,
  ) => {
    try {
      const { data } = (await $api.categoriesService.getCategoryBySlug(slug, {
        ...aggsQuery,
        // @ts-ignore
        includes: ['branch', 'children'],
        'order[popular]': 'desc',
      })) as { data: CategoryExtended };

      if (!data) throw new Error('404 error');

      await getCatalogNav(data);

      category.value = data;
      categoryAggs.value = getCategoryAggs(category.value);
      categoryProducts.value = category.value?.products;
      categoryHeader.value = category.value.contents?.find(
        (block: any) => block.type === 'catalog-head',
      )?.data;
      categoryContent.value = category.value.contents?.filter(
        (block: any) => block.type !== 'catalog-head',
      );
      pagesCount.value = Math.ceil(
        Number(category.value?.products_meta_count) / aggsQuery.limit,
      );
    } catch (e) {
      if (ssrContext) ssrContext.res.statusCode = 404;
      else error({ statusCode: 404 });
    }
  };

  return {
    fetchCategory,
    category,
    categoryAggs,
    categoryHeader,
    categoryContent,
    categoryProducts,
    catalogNavItems,
    pagesCount,
    getPageAggs,
  };
};

export default useFetchCategory;

export default {
  __name: 'UiPackeryItem',
  props: {
  itemClass: {
    type: String,
    default: 'item',
  },
},
  setup(__props) {



return { __sfc: true, }
}

}

import { defineComponent, ref } from '@nuxtjs/composition-api';
import SolutionsCarouselSlideMobile from '~/components/sections/sliderSection/SolutionsCarouselSlideMobile.vue';

export default defineComponent({
  components: { SolutionsCarouselSlideMobile },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  setup(_props, { emit }) {
    const swiper = ref();
    const swiperOptions = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 180,
        snapOnRelease: true,
      },
      on: {
        activeIndexChange: () => {
          emit('slide-change', swiper.value.$swiper.activeIndex);
        },
      },
    };

    return {
      swiper,
      swiperOptions,
    };
  },
});

import { GetterTree, MutationTree } from 'vuex';
import { RootState } from 'store/index';

interface DeviceConfigState {
  isPc: null | boolean;
}
export const state = (): DeviceConfigState => ({
  isPc: null,
});

type DeviceState = ReturnType<typeof state>;

export const getters: GetterTree<DeviceState, RootState> = {
  getIsPc: (state) => state.isPc,
};

export const mutations: MutationTree<DeviceState> = {
  setUserDevice: (state, payload: boolean) => (state.isPc = payload),
};

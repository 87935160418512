export default {
  __name: 'UiSpinner',
  props: {
  value: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const delays = {
  1: 'animation-delay-0',
  2: 'animation-delay-[200ms]',
  3: 'animation-delay-[400ms]',
  4: 'animation-delay-[600ms]',
};



return { __sfc: true,delays }
}

}

import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { YouTubePlayer } from 'vue-youtube-embed';

export default defineComponent({
  components: { YouTubePlayer },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const videoId = computed(() => {
      const url = new URL(props.data.link);
      const isYTBrowserLink = url.pathname === '/watch';
      return isYTBrowserLink
        ? url.searchParams.get('v')
        : url.pathname.replace('/', '');
    });
    const player = ref();
    const isVideoShowed = ref(false);
    const handleReady = (event) => {
      player.value = event.target;
      player.value.playVideo();
    };
    return {
      videoId,
      isVideoShowed,
      handleReady,
    };
  },
});
